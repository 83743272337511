@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

.oswald-font {
	font-family: "Oswald", sans-serif;
}

::selection {
	background: #9acd32;
	color: #fff;
}
